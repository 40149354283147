import React from 'react';
import {PortableText} from '@portabletext/react';
import { useTranslation, Trans } from 'react-i18next';
import { motion } from 'framer-motion';
import { images } from '../../constants';
import { AppWrap, MotionWrap } from '../../wrapper';
import './Career.scss';
import { useForm, ValidationError } from '@formspree/react'
import { AiOutlineSend } from 'react-icons/ai';
import { MantineProvider } from '@mantine/core';
import { NotificationsProvider } from '@mantine/notifications';
import { showNotification } from '@mantine/notifications';
import { BsArrowLeftRight } from 'react-icons/bs';
import { HiCheckCircle } from "react-icons/hi";

const Career = (props) => {
  const { i18n, t } = useTranslation();
  const { careerData } = props['props'][0];
  const variants = {
    show: {
      y: 0,
      opacity: 1,
      transition: { 
        y: { stiffness: 1000, velocity: -100 },
        staggerChildren: 0.07, 
        delayChildren: 0.2 
      }
    },
    hidden: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 }, 
        staggerChildren: 0.05, 
        staggerDirection: -1 
      }
    }
  };

  const [state, handleSubmit] = useForm("mvoygewo");

  if (!state.submitting && state.succeeded) {
    showNotification({
      title: t('Thank you for contacting us!'),
      icon: <HiCheckCircle />,
    })
  }

  return (
    <section className="career">
      <NotificationsProvider position='bottom-center' />
      <motion.div initial="hidden"
        whileInView="show"
        variants={variants}
        viewport={{ once: true }}
      >
        <motion.div>
          {careerData.map((career, index) => (
            <PortableText value={career.joinUs} key={index} />
          ))}
        </motion.div>
        <motion.form variants={variants} encType="multipart/form-data" onSubmit={handleSubmit} className="career__form">
          <div>
            <label htmlFor="full-name">
            <Trans>Full Name</Trans>
            </label>
            <input
              id="full-name"
              type="text" 
              name="full-name"
              placeholder={t('Full Name')}
              required
            />
            <ValidationError 
              prefix="Full Name" 
              field="full-name"
              errors={state.errors}
            />
          </div>
          <div>
            <label htmlFor="email">
            <Trans>Email</Trans>
            </label>
            <input
              id="email"
              type="email" 
              name="email"
              placeholder={t('Email')}
              required
            />
            <ValidationError 
              prefix="Email" 
              field="email"
              errors={state.errors}
            />
          </div>
          <div>
            <label htmlFor="occupation">
              <Trans>Occupation</Trans>
            </label>
            <select name="occupation" id="occupation" required>
              <option value="Please select" defaultValue="" disabled=""><Trans>Please select</Trans></option>
              <option value="Douga Artist"><Trans>Douga Artist</Trans></option>
              <option value="Genga Artist"><Trans>Genga Artist</Trans></option>
              <option value="2D FX Animator"><Trans>2D FX Animator</Trans></option>
              <option value="Other"><Trans>Other</Trans></option>
            </select>
            <ValidationError 
              prefix="occupation" 
              field="occupation"
              errors={state.errors}
            />
          </div>
          <div>
            <label htmlFor="resume">
            <Trans>Resume</Trans>
            </label>
            <input type="file" name="resume" />
            <ValidationError 
              prefix="Resume" 
              field="resume"
              errors={state.errors}
            />
          </div>
          <div>
            <label htmlFor="message"><Trans>Your message</Trans></label>
            <textarea
              id="message"
              name="message"
              placeholder={t('Enter a message...')}
              rows={6}
              cols={6}
              required
            />
            <ValidationError 
              prefix="Message" 
              field="message"
              errors={state.errors}
            />
          </div>
          <button type="submit" disabled={state.submitting}>
          <Trans>Submit</Trans><AiOutlineSend />
          </button>
        </motion.form>
      </motion.div>
    </section>
  );
};

export default AppWrap(Career,
  'career',
  'app__background--white',
);
