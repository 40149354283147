import React from 'react';
import {PortableText} from '@portabletext/react';
import { motion } from 'framer-motion';
import { images } from '../../constants';
import { AppWrap, MotionWrap } from '../../wrapper';
import './About.scss';

const About = (props) => {
  const { aboutData } = props['props'][0];

  const variants = {
      show: {
          y: 0,
          opacity: 1,
          transition: { 
          y: { stiffness: 1000, velocity: -100 },
          staggerChildren: 0.07, 
          delayChildren: 0.2 
          }
      },
      hidden: {
          y: 50,
          opacity: 0,
          transition: {
          y: { stiffness: 1000 }, 
          staggerChildren: 0.05, 
          staggerDirection: -1 
          }
      }
  };

  return (
    <section className="about">
      <motion.div
        initial="hidden"
        whileInView="show"
        variants={variants}
		    viewport={{ once: true }}
        className="about__intro"
      >
        <motion.div variants={variants}>
          {aboutData.map((about, index) => (
            <div key={`about-${index}`}>
              <PortableText value={about.aboutUs} key={`about-${index}`} />
              <PortableText value={about.aboutUsAdditional} key={`about-add-${index}`} />
            </div>
          ))}
        </motion.div>
      </motion.div>
      <motion.iframe 
      initial="hidden"
      whileInView="show"
      variants={variants}
      viewport={{ once: true }}
      className="map" 
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3241.6443634918546!2d139.69434325082415!3d35.66113278010174!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188ca9638bb597%3A0xd84732a5e3b32995!2z44CSMTUwLTAwNDIgVG9reW8sIFNoaWJ1eWEgQ2l0eSwgVWRhZ2F3YWNoxY0sIDM04oiSMyDjgrXjgqTjg4jjg7zjg5Pjg6tJSUkg77yV6ZqO!5e0!3m2!1sen!2sjp!4v1662015200552!5m2!1sen!2sjp" width="800" height="600" frameBorder="0" loading="lazy" title="map"></motion.iframe>
    </section>
  );
};

export default AppWrap(About,
  'about',
  'app__background--white',
);
