import React, { useState, useEffect } from 'react';
import { client } from '../../client';
import { useTranslation } from 'react-i18next';
import {PortableText} from '@portabletext/react'
import { motion } from 'framer-motion';
import { images } from '../../constants';
import { AppWrap, MotionWrap } from '../../wrapper';
import './Intro.scss';

const Intro = () => {
  const { i18n } = useTranslation();

  const variants = {
    show: {
      y: 0,
      opacity: 1,
      transition: { 
        y: { stiffness: 1000, velocity: -100 },
        staggerChildren: 0.07, 
        delayChildren: 0.2 
      }
    },
    hidden: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 }, 
        staggerChildren: 0.05, 
        staggerDirection: -1 
      }
    }
  };

  return (
    <section className="intro">
      <motion.div
        initial="hidden"
        whileInView="show"
        variants={variants}
		    viewport={{ once: true }}
        className="intro__intro"
      >
        {i18n.language === "en" ?
          <motion.div variants={variants}>
            <p>Unkai was founded in Japan by people looking to bring the Japanese passion and quality of animation to the world through an international minded working-environment. Our focus is on effect animation for 2D as well as 3D productions, including but not limited to shows, movies, commercials and games.</p>
          </motion.div>
          :
          <motion.div variants={variants}>
            <p>雲海株式会社は国際的なマインドで日本のアニメ技術と情熱を世界に<br />伝えようと志す人々により創立されました。<br />アニメ、映画、広告、ゲームや、<br />その枠を超えた2Dと3Dエフェクト作品制作に力を入れています。</p>
          </motion.div>
        }
      </motion.div>
    </section>
  );
};

export default AppWrap(Intro,
  'intro',
  'app__background--transparent',
);
