import React from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation} from 'react-i18next';
import { HiGlobeAlt } from 'react-icons/hi';
import { AppWrap } from '../../wrapper';
import './Footer.scss';

const Footer = () => {
  const { i18n } = useTranslation();
  const location = useLocation();
  const currentLocation = location.pathname.replace(/^\/[\w\d]+\//, '');
  const currentYear = new Date().getFullYear();
  let navigate = useNavigate();

  const changeLanguage = event => {
    i18n.changeLanguage(event.target.value);
    if (location.pathname === '/ja' && event.target.value !== 'ja') {
      navigate(`/`);
    } else {
      navigate(`/${event.target.value !== 'en' ? event.target.value + location.pathname : currentLocation }`);
    }
  };

  return (
    <footer className="footer">
      <div className="footer__container">
        <p className='footer__copyright'>© UNKAI {currentYear}. All rights reserved.</p>
        <ul className="footer__navbar">
          {["Home", "About", "Projects", "Career", "Contact"].map((item, index) => {
            return (
              <li className="app__flex p-text" key={`link-footer-${item}-${index}`}>
                <Link to={`${i18n.language !== 'en' ? '/' + i18n.language + '/' : '/'}${item.toLowerCase() === 'home' ? '' : item.toLowerCase()}`} reloadDocument>{item}</Link>
              </li>
            )
          })}
          <li key={'link-lang'}>
            <HiGlobeAlt />
            <select id="language" value={i18n.language} onChange={changeLanguage}>
              <option value="en">EN</option>
              <option value="ja">JA</option>
            </select>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default AppWrap(Footer, 'footer');
