import logo from '../assets/logo.svg';
import logoWhite from '../assets/logo-white.svg';
import unkaikanji from '../assets/kanji.webp';
import noise from '../assets/noise.mp4';

export default {
  logo,
  logoWhite,
  unkaikanji,
  noise
};
