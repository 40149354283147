import React, {useRef} from 'react';
import { useTranslation } from 'react-i18next';
import { Intro, Footer, Hero, Projects, CallToAction } from './container';
import { Navbar } from './components';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import './App.scss';

function App() {
  const progressBarRef = useRef(null);
  const { i18n, t } = useTranslation();

  return (
    <div className="app">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t('UNKAI | Animation Studio based in Japan')}</title>
        <meta property="og:title" key="og:title" content={t('UNKAI | Animation Studio based in Japan')} />
      </Helmet>
      <LoadingBar ref={progressBarRef} color="var(--color-primary)" shadow={false} height={4} />
      <Navbar />
      <Hero />
      <Intro />
      <Projects limit={4} filters={false} progressBarRef={progressBarRef} />
      <CallToAction />
      <Footer />
    </div>
  );
}

export default App;
