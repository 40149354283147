import React, { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const AppWrap = (Component, idName, classNames) => function HOC(...props) {
  const { i18n } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname == '/ja' || location.pathname.includes('/ja/')) {
      if (!document.referrer) {
        i18n.changeLanguage('ja');
      }
    } else {
      if (!i18n.language) {
        i18n.changeLanguage('en');
      }
      if (i18n.language == 'ja' || i18n.language == 'ja-JP') {
        i18n.changeLanguage('ja');
      } else {
        i18n.changeLanguage('en');
      }
    }
    document.documentElement.setAttribute('lang', i18n.language);
  }, [i18n.language, location]);

  return (
    <div id={idName} className={`app__container ${classNames}`}>
      <div className="app__wrapper app__flex">
        <Component props={props} />
      </div>
    </div>
  );
};

export default AppWrap;
