import React,  { useState, useEffect, useRef } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { urlFor, client } from '../client';
import LoadingBar from 'react-top-loading-bar';
import { Header, Footer, About, CallToAction } from '../container';
import { Navbar } from '../components';
import { Skeleton } from '@mantine/core';
import { Helmet } from "react-helmet";
import '../App.scss';

function AboutPage() {
    const progressBarRef = useRef(null);
    const { i18n, t } = useTranslation();
    const [aboutData, setAboutData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    
    useEffect(() => {
        progressBarRef.current.continuousStart();
        setIsLoading(true);
        const query = `*[_type == "about" && __i18n_lang == "${i18n.language}"]`;
        client.fetch(query).then((data) => {
        setAboutData(data);
        setIsLoading(false);
        progressBarRef.current.complete();
        });
    }, [i18n.language]);

    return (
        <div className="app">
            <Helmet>
              <meta charSet="utf-8" />
              <title>{`${aboutData[0]?.title} - ${t('UNKAI | Animation Studio based in Japan')}`}</title>
              <meta name="description" content={aboutData[0]?.description} />
              <meta property="og:title" key="og:title" content={`${aboutData[0]?.title} - ${t('UNKAI | Animation Studio based in Japan')}`} />
              <meta property="og:description" key="og:description" content={aboutData[0]?.description} />
            </Helmet>
            <LoadingBar ref={progressBarRef} color="var(--color-primary)" shadow={false} height={4} />
            <Navbar />
            <Header title={t('About Us')} />
            {isLoading ?
              <div className="container">
                <Skeleton height={30} width="100%" radius={0} />
                <Skeleton height={30} width="100%" radius={0} />
                <Skeleton height={30} width="100%" radius={0} />
                <Skeleton height={30} width="100%" radius={0} />
                <Skeleton height={30} width="100%" radius={0} />
                <Skeleton height={30} width="100%" radius={0} />
                <Skeleton height={30} width="100%" radius={0} />
                <Skeleton height={30} width="100%" radius={0} />
                <Skeleton height={30} width="100%" radius={0} />
                <Skeleton height={30} width="100%" radius={0} />
                <Skeleton height={30} width="100%" radius={0} />
                <Skeleton height={30} width="100%" radius={0} />
                <Skeleton height={30} width="100%" radius={0} />
                <Skeleton height={30} width="100%" radius={0} />
                <Skeleton height={30} width="100%" radius={0} />
                <Skeleton height={30} width="100%" radius={0} />
              </div>
              :
              <About aboutData={aboutData} />
            }
            <CallToAction />
            <Footer />
        </div>
    );
  }
  
  export default AboutPage;
  