import React, { useState, useEffect } from 'react';
import { urlFor, client } from '../../client';
import {PortableText, toPlainText} from '@portabletext/react';
import { images } from '../../constants';
import { motion, useViewportScroll, useTransform } from 'framer-motion';
import { useTranslation, Trans } from 'react-i18next';
import { AppWrap } from '../../wrapper';
import './Hero.scss';

const Hero = () => {
  const { i18n } = useTranslation();
  const { scrollYProgress } = useViewportScroll();
  const translateY = useTransform(scrollYProgress, [0, 1], [0, 260]);
  const opacity = useTransform(scrollYProgress, [0, 0.75], [0.3, 0.05]);
  const scale = useTransform(scrollYProgress, [0, 1], [1, 1.5]);
  const [heroData, setHeroData] = useState([]);

  useEffect(() => {
    const query = `*[_type == "home" && __i18n_lang == "${i18n.language}"]`;
    client.fetch(query).then((data) => {
      setHeroData(data);
    });
  }, [i18n.language]);

  const variants = {
    show: {
      y: 0,
      opacity: 1,
      transition: {  
        y: { stiffness: 1000, velocity: -100 },
        staggerChildren: 0.07, 
        delayChildren: 0.2 
      }
    },
    hidden: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 }, 
        staggerChildren: 0.05, 
        staggerDirection: -1 
      }
    }
  };

  return (
    <section className="hero">
      {/* <video id="background-video" autoPlay loop muted>
      <source src={images.hero} type="video/mp4"/>
    </video>   */}
      <motion.img 
        initial={{ x: 0 }}
        whileInView= {{ x: 0 }}
        style={{translateY, opacity}}
        transition={{
          scale: { stiffness: 1000, velocity: -100 },
        }}
        src={images.unkaikanji} 
        height="100vh" />
      {/* <motion.div
        className="unkai"
        initial={{ x: 0 }}
        whileInView= {{ x: 0 }}
        style={{translateY, opacity}}
        transition={{
          scale: { stiffness: 1000, velocity: -100 },
        }}
      >
        UNKAI
      </motion.div> */}
      {i18n.language === "en" ?
        <motion.h1
          variants={variants}
          initial="hidden"
          whileInView="show"
          viewport={{ once: true }}
        >
          Animating anything from 2D to 3D, video games to commercials, we send rockets into space and light cars on fire (digitally)
        </motion.h1>
      :  
        <motion.h1
          className="centered"
          variants={variants}
          initial="hidden"
          whileInView="show"
          viewport={{ once: true }}
        >
          アニメーションに <br />
          エフェクトを、<br />
          人の心に <br />
          感動を。
        </motion.h1>
      }
      <motion.span className="scroll"></motion.span>
      <motion.span className="scroll"></motion.span>
      <motion.span className="scroll"></motion.span>
    </section>
  )
};

export default AppWrap(Hero, 'home');
