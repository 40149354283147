import React, { useState, useEffect, useRef } from 'react';
import {PortableText, toPlainText } from '@portabletext/react'
import { images } from '../../constants';
import { YoutubeEmbed } from '../../components';
import { motion } from 'framer-motion';
import { useRoutes, useParams } from "react-router-dom";
import { urlFor, client } from '../../client';
import { useTranslation, withTranslation, Trans } from 'react-i18next';
import { HiExternalLink } from 'react-icons/hi';
import { Skeleton } from '@mantine/core';
import LoadingBar from 'react-top-loading-bar';
import { AppWrap } from '../../wrapper';
import { Helmet } from "react-helmet";
import './Project.scss';

const Project = (props) => {
  const { progressBarRef } = props['props'][0];
  const { t, i18n } = useTranslation();
  const [projectData, setProjectData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  let { project } = useParams();

  useEffect(() => {
    progressBarRef.current.continuousStart();
    setIsLoading(true);
    const query = `*[_type == "works" && slug == "${project}" && __i18n_lang == "${i18n.language}"]`;
    client.fetch(query).then((data) => {
      setProjectData(data);
      setIsLoading(false);
      progressBarRef.current.complete();
    });
  }, [project, i18n.language, progressBarRef]);

  const variants = {
    show: {
        y: 0,
        opacity: 1,
        transition: { 
        y: { stiffness: 1000, velocity: -100 },
        staggerChildren: 0.07, 
        delayChildren: 0.2 
        }
    },
    hidden: {
        y: 50,
        opacity: 0,
        transition: {
        y: { stiffness: 1000 }, 
        staggerChildren: 0.05, 
        staggerDirection: -1 
        }
    }
  };

  const variantsAlt = {
    show: {
      opacity: 1,
      transition: { 
        staggerChildren: 0.17, 
        delayChildren: 0.75 
      }
    },
    hidden: {
      opacity: 0,
      transition: {
        staggerChildren: 0.15, 
        staggerDirection: -1 
      }
    }
  };

  if (isLoading) {
    return (
      <div className="project">
        <section className="project__header">
          <h1><Skeleton height={40} width="50vw" radius={0} /></h1>
        </section>
        <motion.section 
          className="project__content"
        >
          <Skeleton height={480} width="100%" radius={0} /> 
          <div className="skeleton-lines">
            <Skeleton height={30} width="100%" radius={0} />
            <Skeleton height={30} width="100%" mt={30} radius={0} />
            <Skeleton height={30} width="100%" mt={30} radius={0} />
            <Skeleton height={30} width="100%" mt={30} radius={0} />
            <Skeleton height={30} width="100%" mt={30} radius={0} />
            <Skeleton height={30} width="100%" mt={30} radius={0} />
            <Skeleton height={30} width="100%" mt={30} radius={0} />
          </div>
        </motion.section>
      </div>
    );
  }
  return (
    <>
    {projectData.map((project, index) => (
      <div className="project" key={index}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`${project?.title} - ${t('UNKAI | Animation Studio based in Japan')}`}</title>
          <meta name="description" content={toPlainText(project?.description)} />
          <meta property="og:title" key="og:title" content={`${project?.title} - ${t('UNKAI | Animation Studio based in Japan')}`} />
          <meta property="og:description" key="og:description" content={toPlainText(project?.description)} />
        </Helmet>
        <section className="project__header" style={{backgroundImage: `url(${urlFor(project.imgUrl)})`}}>
          <motion.h1 
          variants={variantsAlt}
          initial="hidden"
          whileInView="show"
          viewport={{ once: true }}>
            {project.title}
          </motion.h1>
        </section>
        <motion.section 
          className="project__content"
          viewport={{ once: true }}
          initial="hidden"
          whileInView="show"
          variants={variants}
        >
          {project.codeLink &&
            <YoutubeEmbed embedId={project.codeLink}  />
          }
          {project.description && 
            <PortableText value={project.description} key={`description-${index}`} />
          }
          {project.animationUrl && 
            <img src={urlFor(project.animationUrl)} alt={project.title}/>
          }
          {project.projectLink && 
            <a href={project.projectLink} target="_blank" rel="noreferrer" className="button"><Trans>View project</Trans><HiExternalLink /></a>
          }
        </motion.section>
      </div>
    ))}
    </>
  )
};

export default AppWrap(Project, 'project');
