import React, {useState, useEffect} from 'react';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import AboutPage from "./routes/about";
import ProjectsPage from "./routes/projects";
import ProjectPage from "./routes/project";
import CareerPage from "./routes/career";
import ContactPage from "./routes/contact";
import ReactGA from "react-ga4";

const root = ReactDOM.createRoot(document.getElementById('root'));
ReactGA.initialize("G-63J4CRMXZX");

root.render(
  <I18nextProvider i18n={i18n}>
    <BrowserRouter forceRefresh>
      <Routes>
        <Route path="/" element={<App />} exact/>
        <Route path="/about" element={<AboutPage/>} exact/>
        <Route path="/projects" element={<ProjectsPage/>} exact/>
        <Route path="/projects/:project" element={<ProjectPage />} />
        <Route path="/career" element={<CareerPage/>} exact/>
        <Route path="/contact" element={<ContactPage/>} exact/>
        <Route path="/ja" element={<App />} exact/>
        <Route path="/ja/about" element={<AboutPage/>} exact/>
        <Route path="/ja/projects" element={<ProjectsPage/>} exact/>
        <Route path="/ja/projects/:project" element={<ProjectPage />} />
        <Route path="/ja/career" element={<CareerPage/>} exact/>
        <Route path="/ja/contact" element={<ContactPage/>} exact/>
      </Routes>
    </BrowserRouter>
  </I18nextProvider>
);

reportWebVitals();
