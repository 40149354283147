import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
// don't want to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init

const resources = {
  en: {
    translation: {
      "UNKAI | Animation Studio based in Japan": "UNKAI | Animation Studio based in Japan",
      "Home": "Home",
      "About": "About",
      "About Us": "About Us",
      "Works": "Projects",
      "Career": "Career",
      "Contact": "Contact",
      "Our works": "Projects",
      "See more": "See more",
      "More projects": "More projects",
      "View project": "View project",
      "New projects coming soon...": "New projects coming soon...",
      "Do you have a project for us?": "Do you have a project for us?",
      "Let's get in touch!": "Let's get in touch!",
      "Contact us": "Contact us",
      "Full Name": "Full Name",
      "Email": "Email",
      "Type of inquiry": "Type of inquiry",
      "Please select": "Please select",
      "Business": "Business",
      "Other": "Other",
      "Your message": "Your message",
      "Enter a message...": "Enter a message...",
      "Occupation": "Occupation",
      "Douga Artist": "Douga Artist",
      "Genga Artist": "Genga Artist",
      "2D FX Animator": "2D FX Animator",
      "Resume": "Resume",
      "Thank you for contacting us!": "Thank you for contacting us!",
      "Submit": "Submit"
    }
  },
  ja: {
    translation: {
      "UNKAI | Animation Studio based in Japan": "UNKAI | アニメーションスタジオ",
      "Home": "ホーム",
      "About": "UNKAIについて",
      "About Us": "UNKAIについて",
      "Works": "プロジェクト",
      "Career": "採用情報",
      "Contact": "お問い合わせ",
      "Our works": "プロジェクト事例",
      "See more": "もっと見る",
      "More projects": "もっと見る",
      "View project": "プロジェクトを見る",
      "New projects coming soon...": "雲海の航海はまだまだ続く・・・",
      "Do you have a project for us?": "エフェクトで困っていることがあれば",
      "Let's get in touch!": "お気軽にご相談ください！",
      "Contact us": "お問い合わせ",
      "Full Name": "姓名",
      "Email": "メールアドレス",
      "Type of inquiry": "お問い合わせ項目",
      "Please select": "ご選択ください",
      "Business": "事業",
      "Other": "その他",
      "Your message": "メッセージ",
      "Enter a message...": "メッセージ",
      "Occupation": "職種",
      "Douga Artist": "動画アーティスト",
      "Genga Artist": "漫画家",
      "2D FX Animator": "2DFXアニメーター",
      "Resume": "履歴書",
      "Thank you for contacting us!": "ご連絡いただきありがとうございます！",
      "Submit": "送信"
    }
  }
};

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    //lng: i18n.language ? i18n.language : 'en',
    fallbackLng: i18n.language ? i18n.language : 'en',
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });


export default i18n;
