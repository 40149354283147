import React, { useState, useRef } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation, withTranslation, Trans, Translation } from 'react-i18next';
import { HiMenuAlt4, HiTranslate, HiGlobeAlt, HiChevronDown } from 'react-icons/hi';
import ReactGA from "react-ga4";
import { motion, useCycle } from 'framer-motion';
import { images } from '../../constants';
import { useIsMobile } from '../../utils/useMediaQuery';
import classNames from 'classnames';
import './Navbar.scss';

const Path = props => (
  <motion.path
    fill="transparent"
    strokeWidth="3"
    stroke="hsl(0, 0%, 18%)"
    strokeLinecap="round"
    {...props}
  />
);

const Navbar = (props) => {
  const [toggle, setToggle] = useState(false);
  const [isOpen, toggleOpen] = useCycle(false, true);
  const containerRef = useRef(null);
  const { height } = 1000;
  const [colorChange, setColorchange] = useState(false);
  const itemIds = ['home', 'about', 'projects', 'career', 'contact'];
  const { i18n } = useTranslation();
  const location = useLocation();
  const currentLocation = location.pathname.replace(/^\/[\w\d]+\//, '');
  const isMobile = useIsMobile();

  let navigate = useNavigate();

  ReactGA.send({ hitType: "pageview", page: location.pathname.replace(/^\/[\w\d]+\//, '') });

  const changeNavbarColor = () =>{
     if(window.scrollY >= 80){
       setColorchange(true);
     } else {
       setColorchange(false);
     }
  };

  const changeLanguage = event => {
    i18n.changeLanguage(event.target.value);
    if (isMobile) {
      toggleOpen();
    }
    if (location.pathname == '/ja' && event.target.value !== 'ja') {
      navigate(`/`);
    } else {
      navigate(`/${event.target.value !== 'en' ? event.target.value + location.pathname : currentLocation }`);
    }
    changeNavbarColor();
  };

  const sidebar = {
    open: (height = 1000) => ({
      clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
      transition: {
        type: "spring",
        stiffness: 20,
        restDelta: 2
      }
    }),
    closed: {
      clipPath: "circle(30px at 40px 40px)",
      transition: {
        delay: 0.5,
        type: "spring",
        stiffness: 400,
        damping: 40
      }
    }
  };
  const variants = {
    open: {
      y: 0,
      opacity: 1,
      transition: { 
        y: { stiffness: 1000, velocity: -100 },
        staggerChildren: 0.07, 
        delayChildren: 0.2 
      }
    },
    closed: {
      y: 50,
      opacity: 0,   
      transition: {
        y: { stiffness: 1000 }, 
        staggerChildren: 0.05, 
        staggerDirection: -1 
      }
    }
  };
  window.addEventListener('scroll', changeNavbarColor);

  return (
    <nav className={classNames(colorChange ? 'navbar navbar--white' : 'navbar', location.pathname.includes('/projects/') && !colorChange && !isOpen ? 'navbar--transparent' : '')}>
      <div className="navbar__container">
        <Link to={`${i18n.language !== 'en' ? '/' + i18n.language : '/'}`} reloadDocument className="navbar__logo">
          <img src={location.pathname.includes('/projects/') && !colorChange && !isOpen ? images.logoWhite : images.logo} height="24px" alt="Unkai" />
        </Link>
        <ul className="navbar__links">
          {["Home", "About", "Projects", "Career", "Contact"].map((item, index) => {
            return (
              <li className="app__flex p-text" key={`link-${item}-${index}`}>
                <Link to={`${i18n.language !== 'en' ? '/' + i18n.language + '/' : '/'}${item.toLowerCase() === 'home' ? '' : item.toLowerCase()}`} reloadDocument>{item}</Link>
              </li>
            )
          })}
          <li key={'link-lang'}>
            <HiGlobeAlt />
            <select id="language" value={i18n.language} onChange={changeLanguage}>
              <option value="en">EN</option>
              <option value="ja">JA</option>
            </select>
          </li>
        </ul>

        <div className={classNames('navbar__mobile', !isOpen ? 'navbar__mobile--closed' : 'navbar__mobile--open')}>  
          <motion.nav
            initial={false}
            animate={isOpen ? "open" : "closed"}
            custom={height}
            ref={containerRef}
          >
            <motion.ul variants={variants}>
              {["Home", "About", "Projects", "Career", "Contact"].map((item, index) => (
                <motion.li
                  variants={variants}
                  whileHover={{ scale: 0.95 }}
                  whileTap={{ scale: 0.95 }}
                  key={`link-mobile-${item.toLowerCase()}-${index}`}
                >
                  <Link to={`${i18n.language !== 'en' ? '/' + i18n.language + '/' : '/'}${item.toLowerCase() === 'home' ? '' : item.toLowerCase()}`} reloadDocument>{item}</Link>
                </motion.li>
              ))}
              <motion.li
                variants={variants}
                whileHover={{ scale: 0.95 }}
                whileTap={{ scale: 0.95 }}
                key={'link-mobile-lang'}
              >
                <HiGlobeAlt />
                <select id="language" value={i18n.language} onChange={changeLanguage}>
                  <option value="en">EN</option>
                  <option value="ja">JA</option>
                </select>
              </motion.li>
            </motion.ul>
            <button onClick={toggleOpen}>
              <svg width="23" height="23" viewBox="0 0 23 23">
                <Path
                  variants={{
                    closed: { d: "M 2 2.5 L 20 2.5" },
                    open: { d: "M 3 16.5 L 17 2.5" }
                  }}
                />
                <Path
                  d="M 2 9.423 L 20 9.423"
                  variants={{
                    closed: { opacity: 1 },
                    open: { opacity: 0 }
                  }}
                  transition={{ duration: 0.1 }}
                />
                <Path
                  variants={{
                    closed: { d: "M 2 16.346 L 20 16.346" },
                    open: { d: "M 3 2.5 L 17 16.346" }
                  }}
                />
              </svg>
            </button>
          </motion.nav>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
