import React, { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import { useTranslation, Trans } from 'react-i18next';
import { AiFillEye, AiOutlineDoubleRight } from 'react-icons/ai';
import { motion } from 'framer-motion';
import { AppWrap, MotionWrap } from '../../wrapper';
import { urlFor, videoAssetFor, client } from '../../client';
import { images } from '../../constants';
import { Skeleton } from '@mantine/core';
import { InView } from 'react-intersection-observer';
import LazyProps from "react-lazy-props";
import './Projects.scss';

const Projects = (props) => {
  const { limit, filters, progressBarRef } = props['props'][0];
  const [isLoading, setIsLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [filterProject, setFilterProject] = useState([]);
  const [activeFilter, setActiveFilter] = useState('All');
  const [animateCard, setAnimateCard] = useState({ y: 0, opacity: 1 });
  const { t, i18n } = useTranslation();
  const [inView, setInView] = useState(false);

  useEffect(() => {
    progressBarRef.current.continuousStart();
    setIsLoading(true);
    const query = limit ? `*[_type == "works" && __i18n_lang == "${i18n.language}"][0..${limit - 1}]` : `*[_type == "works" && __i18n_lang == "${i18n.language}"]`;

    client.fetch(query).then((data) => {
      setProjects(data);
      setFilterProject(data);
      setIsLoading(false);
      progressBarRef.current.complete();
    });
  }, [i18n.language, progressBarRef]);

  const handleProjectFilter = (item) => {
    setActiveFilter(item);
    setAnimateCard([{ y: 100, opacity: 0 }]);

    setTimeout(() => {
      setAnimateCard([{ y: 0, opacity: 1 }]);

      if (item === 'All') {
        setFilterProject(projects);
      } else {
        setFilterProject(projects.filter((project) => project.tags.includes(item)));
      }
    }, 500);
  };

  const variants = {
    show: {
      opacity: 1,
      transition: { 
        staggerChildren: 0.17, 
        delayChildren: 0.75 
      }
    },
    hidden: {
      opacity: 0,
      transition: {
        staggerChildren: 0.15, 
        staggerDirection: -1 
      }
    }
  };

  if (isLoading) {
    const n = 4;

    return (
      <div className={`projects ${filters ? 'projectsPage' : ''}`}>
        <motion.section 
          className="projects__portfolio"
        >
          {[...Array(n)].map((e, i) => (
            <motion.div className="projects__portfolio--item" key={i} variants={variants}>
              <div className="projects__portfolio--img">
                <Skeleton height="100%" width="100%" radius={0} /> 
              </div>
            </motion.div>
          ))}
        </motion.section>
      </div>
    );
  }

  return (
    <section className={`projects ${filters ? 'projectsPage' : ''}`}>
      {filters && 
        <div className="projects__filter">
          {['2D FX Animation', 'Clean/In-Between/Color', 'Character Animation', 'Character Design', 'Background', 'All'].map((item, index) => (
            <div
              key={index}
              onClick={() => handleProjectFilter(item)}
              className={`projects__filter--item ${activeFilter === item ? 'projects__filter--item-active' : ''}`}
            >
              {item}
            </div>
          ))}
        </div> 
      }
      <InView onChange={(isInView) => inView === true ? null : setInView(isInView)}>
        <motion.div
          className="projects__portfolio"
          initial="hidden"
          whileInView="show"
          variants={variants}
          viewport={{ once: true }}
        >
          {filterProject.map((project, index) => (
            <motion.div className="projects__portfolio--item" key={index} variants={variants}>
              <Link to={`${i18n.language !== 'en' ? '/' + i18n.language + '/' : '/'}projects/${project.slug}`} reloadDocument>
                <div
                  className="projects__portfolio--img"
                >
                  <motion.img src={urlFor(project.imgUrl)} alt={project.name} />
                  <motion.div
                    whileHover={{ opacity: [0, 1] }}
                    transition={{ duration: 0.25, ease: 'easeInOut', staggerChildren: 0.5 }}
                    className="projects--hover"
                  >
                    <h4 className="projects__portfolio--title">{project.title}</h4>
                    {project.videoAnimation &&
                      <LazyProps unloaded={inView}>
                        <video
                          title={project.name}
                          loop 
                          autoPlay 
                          muted
                          playsInline
                        >
                          <source src={videoAssetFor(project.videoAnimation).asset.url} type={`video/webm`} />
                        </video>
                      </LazyProps>
                    }
                    <motion.div
                      whileInView={{ scale: [0, 1] }}
                      whileHover={{ scale: [1, 0.90] }}
                      transition={{ duration: 0.25 }}
                      className="app__flex"
                    >
                      <AiFillEye />
                    </motion.div>
                    {/* <a href={project.codeLink} target="_blank" rel="noreferrer">
                      <motion.div
                        whileInView={{ scale: [0, 1] }}
                        whileHover={{ scale: [1, 0.90] }}
                        transition={{ duration: 0.25 }}
                        className="app__flex"
                      >
                        <AiFillGithub />
                      </motion.div>
                    </a> */}
                  </motion.div>
                </div>

                {/* <div className="project__content app__flex">
                  <h4 className="bold-text">{project.title}</h4>
                  <p className="p-text" style={{ marginTop: 10 }}>{project.description}</p>
                </div> */}
              </Link>
            </motion.div>
          ))}
          {filterProject.length % 2 === 1 && 
            <motion.div className="projects__portfolio--item projects__portfolio--placeholder" key="placeholder-item" variants={variants}>
              <img src={images.logoWhite} height="44px" alt="Unkai" />
              <h4 className="projects__portfolio--title"><Trans>New projects coming soon...</Trans></h4>
              <video
                loop 
                autoPlay 
                muted
                playsInline
              >
                <source src={images.noise} type={`video/mp4`} />
              </video>
            </motion.div>
          }
        </motion.div>
      </InView>

      {!filters &&
        <div className="projects__actions">
          <Link to={`${i18n.language !== 'en' ? '/' + i18n.language + '/' : '/'}projects`} reloadDocument className="button">
            <Trans>More projects</Trans>
          </Link>
          <Link to={`${i18n.language !== 'en' ? '/' + i18n.language + '/' : '/'}projects`} reloadDocument className="button button--mobile">
            <Trans>More projects</Trans>
          </Link>
        </div>
      }
    </section>
  );
};

export default AppWrap(Projects, 'projects', 'app__background--white');
