import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation, withTranslation, Trans, Translation } from 'react-i18next';
import { motion } from 'framer-motion';
import { images } from '../../constants';
import { AppWrap, MotionWrap } from '../../wrapper';
import { AiOutlineComment } from 'react-icons/ai';
import './CallToAction.scss';
import { urlFor, client } from '../../client';

const CallToAction = () => {
  const { i18n } = useTranslation();
  const location = useLocation();
  const currentLocation = location.pathname.replace(/^\/[\w\d]+\//, '');

  const variants = {
    show: {
      y: 0,
      opacity: 1,
      transition: { 
        y: { stiffness: 1000, velocity: -100 },
        staggerChildren: 0.07, 
        delayChildren: 0.2 
      }
    },
    hidden: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 }, 
        staggerChildren: 0.05, 
        staggerDirection: -1 
      }
    }
  };

  return (
    <section className="contactUs">
      <h4><Trans>Do you have a project for us?</Trans> <strong><Trans>Let's get in touch!</Trans></strong></h4>
      <Link to={`${i18n.language !== 'en' ? '/' + i18n.language + '/' : '/'}contact`} reloadDocument className="button">
        <Trans>Contact us</Trans><AiOutlineComment />
      </Link>
    </section>
  );
};

export default AppWrap(CallToAction,
  'services',
  'app__background--dark',
);
