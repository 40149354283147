import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { images } from '../../constants';
import { AppWrap, MotionWrap } from '../../wrapper';
import { AiOutlineComment } from 'react-icons/ai';
import './Header.scss';
import { urlFor, client } from '../../client';

const Header = (props) => {
  const { title, background, video} = props['props'][0];

  const variants = {
    show: {
      opacity: 1,
      transition: { 
        staggerChildren: 0.17, 
        delayChildren: 0.75 
      }
    },
    hidden: {
      opacity: 0,
      transition: {
        staggerChildren: 0.15, 
        staggerDirection: -1 
      }
    }
  };

  return (
    <header>
      { video ? 
        <video id="background-video" autoPlay loop muted>
          <source src={background} type="video/mp4"/>
        </video> : '' 
      }
      <motion.h1 
        variants={variants}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true }}>
          {title}
      </motion.h1>
    </header>
  );
};

export default AppWrap(Header,
  'header',
  
  'app__background--light',
);
