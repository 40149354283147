import React, {useRef} from 'react';
import { useTranslation } from 'react-i18next';
import { Header, Footer, Projects, CallToAction } from '../container';
import { Navbar } from '../components';
import LoadingBar from 'react-top-loading-bar';
import '../App.scss';

function ProjectsPage() {
    const progressBarRef = useRef(null);
    const { t } = useTranslation();

    return (
        <div className="app app__projects">
            <LoadingBar ref={progressBarRef} color="var(--color-primary)" shadow={false} height={4} />
            <Navbar />
            <Header title={t('Our works')} />
            <Projects filters={true} progressBarRef={progressBarRef} />
            <CallToAction />
            <Footer />
        </div>
    );
  }
  
  export default ProjectsPage;
  