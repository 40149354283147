import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { images } from '../../constants';
import { AppWrap, MotionWrap } from '../../wrapper';
import './Services.scss';
import { urlFor, client } from '../../client';

const Services = () => {

  const variants = {
    show: {
      y: 0,
      opacity: 1,
      transition: { 
        y: { stiffness: 1000, velocity: -100 },
        staggerChildren: 0.07, 
        delayChildren: 0.2 
      }
    },
    hidden: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 }, 
        staggerChildren: 0.05, 
        staggerDirection: -1 
      }
    }
  };

  return (
    <section className="services">
      <motion.div
        initial="hidden"
        whileInView="show"
        variants={variants}
		    viewport={{ once: true }}
        className="services__intro"
      >
        <motion.h2 variants={variants}>Specialized in <strong>2D FX</strong></motion.h2>
        <motion.p variants={variants}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin tempus, nisi vel dignissim tempor, diam est aliquet orci, non varius eros elit vitae purus. Quisque lobortis condimentum tempus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin tempus, nisi vel dignissim tempor, diam est aliquet orci, non varius eros elit vitae purus. Quisque lobortis condimentum tempus.</motion.p>
      </motion.div>
      <motion.div
        initial={{opacity: 0}}
        whileInView={{opacity: 1}}
        transition={{ duration: 1, delay: 0.25 }}
        viewport={{ once: true }}
      >
      <video
        id="background-video"
        autoPlay
        loop
        muted
      >
          <source src={images.fx} type="video/mp4"/>
      </video>
      </motion.div>
    </section>
  );
};

export default AppWrap(Services,
  'services',
  'app__background--white',
);
