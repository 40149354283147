import React, {useRef} from 'react';
import { CallToAction, Footer, Project } from '../container';
import { Navbar } from '../components';
import LoadingBar from 'react-top-loading-bar';
import '../App.scss';

function ProjectPage() {
    const progressBarRef = useRef(null);

    return (
        <div className="app">
            <LoadingBar ref={progressBarRef} color="var(--color-primary)" shadow={false} height={4} />
            <Navbar />
            <Project progressBarRef={progressBarRef} />
            <CallToAction />
            <Footer />
        </div>
    );
  }
  
  export default ProjectPage;
  